<template>
  <div class="container">

    <div>
      <div class="div3">
        <img :src="data.pic" />
      </div>
      <div class="one">{{data.title}}</div>
      <p class="two" v-html="data.content">{{data.content}}</p>
    </div>
  </div>
</template>


<script>
  import {getEnterpriseList} from "../../../api/zoujindaweijia"
  export default {
    name: "Si",
    data(){
      return{
        data:[]
      }
    },
    mounted() {
      getEnterpriseList().then(res=>{
        let data = res.data[3];
        this.data = data;
      })

    }
  }
</script>


<style scoped>
  .container{
    height: 52.8646vw;
  }
  .div3{
    position: absolute;
    margin-left:54.8438vw ;
    margin-top: 6vw;
  }
  img{
    width: 32.917vw;
    /*height: 25vw;*/

  }
  .one{
    position: absolute;
    margin-left: 7.8125vw;
    /*margin-top: 4vw;*/
    width: 20vw;
    /*height: 33px;*/
    font-size: 2.2vw;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: left;
    color: #000000;

  }
  /deep/p span{
    color: rgb(153, 153, 153)!important;
    font-size: 14px!important;
    line-height: 28px!important;
    white-space: normal;
    font-family: Tahoma, Verdana, Arial, sans-serif;
  }
  .two{
    position: absolute;
    width: 43.7917vw;
    /*height: 15.9896vw;*/
    font-size: 1.0417vw;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #000000;
    margin-left: 7.8125vw;
    margin-top: 4vw;
  }

</style>